import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import { toast } from "react-toastify";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Counter from "./Counter";
import { DISCORD_LINK, TWITTER_LINK } from "../App";
import { isMobile } from "react-device-detect";
import Disclaimer from "./Disclaimer";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { startDate } from "./Counter";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const STATE_STAYTUNED = 1;
const STATE_CONNECT = 2;
const STATE_MINT = 3;
const STATE_MINTING = 4;
const STATE_MINTED = 5;

const MintApp = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  // Maxima cantidad de NFT que mintear en WL
  const MAXAMOUNTWL = 3;
  // Maxima cantidad de NFT que mintear en public
  const MAXAMOUNTPUBLIC = 20;

  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);

  // NFTs que ha minteado el usuario, almacenado en el local storage
  const [mintAmountStorage, setMintAmountStorage] = useState(0);

  // Cantidad que va a mintear el usuario
  const [mintAmount, setMintAmount] = useState(0);

  // Pertenece el usuario a la WL
  const [isWhitelisted, setIsWhiteListed] = useState(false);

  const [onlyWhitelisted, setOnlyWhitelisted] = useState(false);

  const [canMint, setCanMint] = useState(true);

  const [stateMint, setMintState] = useState(STATE_CONNECT);

  const DATEWL = startDate; //new Date("May 5, 2022 14:59:00 +0200"); //new Date("May 6, 2022 16:00:00 +0200");

  // Configuracion del smart contract
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const checkMintAmountUser = () => {
    return blockchain.smartContract.methods
      .addressMintedBalance(window.ethereum.selectedAddress)
      .call()
      .then((value) => {
        console.log("MintedAmount: ", value);
        setMintAmountStorage(value);
      });
  };

  /**
   * Mintea el NFT
   */
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        setMintState(STATE_MINTED);
        dispatch(fetchData(blockchain.account));
      });
  };

  /**
   * Decrementa en una unidad la cantidad de NFT's a mintear
   */
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 0) {
      newMintAmount = 0;
    }
    setMintAmount(newMintAmount);
  };

  /**
   * Incrementa en una unidad la cantidad de NFT's a mintear,
   * poniendo como limite 2 o 2 menos los que haya minteado ya
   */
  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    const MAX = onlyWhitelisted ? MAXAMOUNTWL : MAXAMOUNTPUBLIC;
    if (newMintAmount > MAX - mintAmountStorage) {
      newMintAmount = MAX - mintAmountStorage < 0 ? 0 : MAX - mintAmountStorage;
    }
    setMintAmount(newMintAmount);
  };

  /**
   * Crea la conexion con el contrato y comprtueba si pertenece a WL
   */
  const getData = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      // checkWhitelisted();
      checkMintAmountUser();

      const isWL = await blockchain.smartContract.methods
        .isWhitelisted(window.ethereum.selectedAddress)
        .call()
        .then((value) => {
          console.log("WL: ", value);
          setIsWhiteListed(value);
          return value;
        });
      // Comprueba si solo pueden mintear gente de WL
      const isOnlyWL = await blockchain.smartContract.methods
        .onlyWhitelisted()
        .call()
        .then((value) => {
          console.log("OnlyWhitelisted: ", value);
          setOnlyWhitelisted(value);
          return value;
        });

      if (isOnlyWL) {
        // Solo gente de whitelist
        if (isWL && mintAmountStorage <= MAXAMOUNTWL) {
          setCanMint(true);
          console.log("CanMintWL: ", true);
        } else {
          console.log("CanMintWL: ", false);
          setCanMint(false);
        }
      } else {
        // Gente de publicSale
        if (mintAmountStorage <= MAXAMOUNTPUBLIC) {
          setCanMint(true);
          console.log("CanMintPS: ", true);
        } else {
          console.log("CanMintPS: ", false);
          setCanMint(false);
        }
      }
    }
  };

  /**
   * Obtiene la configuracion del smart contract
   */
  const getConfig = async () => {
    const configResponse = await fetch("config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const [pathImgHeader, setPathImgHeader] = useState(
    "assets/images/mint/steps.svg"
  );

  useEffect(async () => {
    const soldOut = data.totalSupply === CONFIG.MAX_SUPPLY;
    if (soldOut) {
      setPathImgHeader("assets/images/mint/so.svg");
    } else if (onlyWhitelisted) {
      setPathImgHeader("assets/images/mint/wl.svg");
    } else if (!onlyWhitelisted && blockchain.account) {
      setPathImgHeader("assets/images/mint/ps.svg");
    } else {
      setPathImgHeader("assets/images/mint/steps.svg");
    }
  }, [onlyWhitelisted]);

  /**
   * Comprueba si ya es momento de whitelist
   */
  useLayoutEffect(() => {
    const today = new Date();
    if (DATEWL.getTime() - today.getTime() <= 0) {
      setMintState(STATE_CONNECT);
    } else {
      setMintState(STATE_STAYTUNED);
    }
    const interval = setInterval(() => {
      const today = new Date();
      if (DATEWL.getTime() - today.getTime() <= 0) {
        setMintState(STATE_CONNECT);
        clearInterval(interval);
      } else {
        setMintState(STATE_STAYTUNED);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  /**
   * Check if metamask is installed in the browser
   */
  const checkMetamaskInstalled = () => {
    //@ts-ignore
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask installed");
    } else {
      // Mandamos una notificacion para que instale metamask
      toast.error("Please install Metamask", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /**
   * Function to copy to the clipboard the address
   */
  const copyToClipboard = () => {
    //@ts-ignore
    navigator.clipboard.writeText(window.ethereum.selectedAddress);
    toast("Account copied to clipboard", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  /**
   * Change to the network defined by the smart contract
   */
  const checkNetwork = async () => {
    try {
      const configResponse = await fetch("config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const config = await configResponse.json();

      //@ts-ignore
      await window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + Number(config.NETWORK.ID).toString(16) }],
        })
        .then(() => {
          console.log("Connected to " + config.NETWORK.NAME);
        });
    } catch (error) {}
  };

  useEffect(() => {
    // Check if metamask is installed
    checkMetamaskInstalled();
    // Check if the network is correct
    checkNetwork();
  }, []);

  const Button = () => {
    return (
      <div className="mt-12 mb-16">
        {stateMint === STATE_STAYTUNED && <Counter />}

        {blockchain.account === "" ||
          (blockchain.smartContract === null && stateMint === STATE_CONNECT && (
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
                // Cambiamos de estado
                setMintState(STATE_MINT);
              }}
              className="text-2xl text-light_yellow_foodbot"
            >
              Connect your wallet {">"}
            </button>
          ))}
        {stateMint === STATE_MINT && (
          <button
            style={
              {
                // backgroundColor: "#FFF468",
                // color: "#382261",
              }
            }
            className="text-4xl py-2 px-9 rounded-xl shadow-mainButton hover:bg-white bg-light_yellow_foodbot text-strong_purple_foodbot"
            onClick={(e) => {
              if (data.totalSupply === CONFIG.MAX_SUPPLY) {
                // All sold out
                toast.error("ALL SOLD OUT!", {
                  position: "top-center",
                  style: { fontFamily: "Upheaval", fontSize: "1vw" },
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else if (
                (onlyWhitelisted && isWhitelisted) ||
                !onlyWhitelisted
              ) {
                e.preventDefault();
                claimNFTs();
                getData();
                // Cambiamos de estado
                setMintState(STATE_MINTING);
                setMintAmountStorage(mintAmount);
                localStorage.setItem("mintAmount", mintAmountStorage + 1);
              } else {
                toast.error("You are not in the WL, sorry!", {
                  position: "top-center",
                  style: { fontFamily: "Upheaval", fontSize: "1vw" },
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }}
          >
            MINT{" "}
            <motion.div
              style={{ display: "inline-block" }}
              animate={{ x: [0, 7, 0] }}
              transition={{
                ease: "easeInOut",
                duration: 0.75,
                repeat: Infinity,
              }}
            >
              {">>"}
            </motion.div>{" "}
          </button>
        )}
        {stateMint === STATE_MINTING && (
          <h1 className="text-4xl" style={{ color: "#A5EF16" }}>
            MINTING...
          </h1>
        )}

        {stateMint === STATE_MINTED && (
          <button
            // style={{ color: "#382261" }}
            className="text-4xl py-2 px-9 rounded-xl hover:bg-white bg-light_yellow_foodbot text-strong_purple_foodbot shadow-mainButton "
            onClick={(e) => {
              window.open(CONFIG.MARKETPLACE_LINK);
            }}
          >
            GO TO OPENSEA{" "}
            <motion.div
              style={{ display: "inline-block" }}
              animate={{ x: [0, 7, 0] }}
              transition={{
                ease: "easeInOut",
                duration: 0.75,
                repeat: Infinity,
              }}
            >
              {">>"}
            </motion.div>{" "}
          </button>
        )}
      </div>
    );
  };

  const Center = () => {
    return (
      <>
        {/* STAY TUNED */}
        {stateMint === STATE_STAYTUNED && (
          <>
            <img src="assets/images/mint/walkie.png" className="w-1/6 mt-14" />
            <h1
              className="mt-4 text-yellow_foodbot"
              style={{
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "22.15px",
              }}
            >
              STAY TUNED
            </h1>
          </>
        )}

        {/* CONNECT */}
        {stateMint === STATE_CONNECT && (
          <div
            className="relative px-20 mt-20"
            style={{ backgroundColor: "#6A4C93" }}
          >
            <img
              src="assets/images/mint/offer.png"
              className="absolute w-1/4 right-0"
              style={{ transform: "translate(50%,-50%)" }}
            />
            <img
              src="assets/images/mint/foodbot_example.png"
              className="absolute w-2/5 left-0"
              style={{ transform: "translate(-50%, -15%)" }}
            />
            <h1 className="text-xl text-white">1 {CONFIG.SYMBOL} costs</h1>
            <h1 className="text-4xl text-yellow_foodbot">
              {CONFIG.DISPLAY_COST} {CONFIG.NETWORK.SYMBOL}
            </h1>
            <h1 className="text-sm text-white">excluding gas fees</h1>
          </div>
        )}

        {/* MINT */}
        {stateMint === STATE_MINT && (
          <div className="mt-4">
            <a
              href={CONFIG.SCAN_LINK}
              className="text-white font-Consolas text-base"
            >
              Address: {CONFIG.CONTRACT_ADDRESS.substring(0, 5)}...
              {CONFIG.CONTRACT_ADDRESS.substring(
                CONFIG.CONTRACT_ADDRESS.length - 4,
                CONFIG.CONTRACT_ADDRESS.length
              )}
            </a>
            <div className="flex flex-row justify-center gap-5 mt-4">
              <button
                className="text-4xl  py-2 px-4 rounded-lg font-Consolas hover:bg-white bg-light_yellow_foodbot text-strong_purple_foodbot shadow-changeButton"
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  decrementMintAmount();
                }}
              >
                -
              </button>
              <div
                className="px-6 flex items-center justify-center rounded-md"
                style={{
                  backgroundColor: "#5E4286",
                }}
              >
                <h1 className="text-center text-5xl font-Consolas text-yellow_foodbot">
                  {mintAmount}
                </h1>
              </div>

              <button
                className="text-4xl  py-2 px-4 rounded-lg font-Consolas hover:bg-white bg-light_yellow_foodbot text-strong_purple_foodbot shadow-changeButton"
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  incrementMintAmount();
                }}
              >
                +
              </button>
            </div>
            <div className="mt-9 ">
              <hr
                className="text-yellow_foodbot bg-yellow_foodbot border-none"
                style={{ height: "1px" }}
              />
              <h1 className="font-Consolas text-xl text-yellow_foodbot">
                {CONFIG.DISPLAY_COST} {CONFIG.NETWORK.SYMBOL} + GAS
              </h1>
            </div>
          </div>
        )}
        {/* MINTING */}
        {stateMint === STATE_MINTING && (
          <div className="mt-4">
            <a
              href={CONFIG.SCAN_LINK}
              className="text-white font-Consolas text-base opacity-30"
            >
              Address: {CONFIG.CONTRACT_ADDRESS.substring(0, 5)}...
              {CONFIG.CONTRACT_ADDRESS.substring(
                CONFIG.CONTRACT_ADDRESS.length - 4,
                CONFIG.CONTRACT_ADDRESS.length
              )}
            </a>
            <div className="flex flex-row justify-center gap-5 mt-4 opacity-30">
              <button
                className="text-4xl  py-2 px-4 rounded-lg font-Consolas bg-light_yellow_foodbot text-strong_purple_foodbot"
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  decrementMintAmount();
                }}
              >
                -
              </button>
              <div
                className="px-6 flex items-center justify-center rounded-md"
                style={{
                  backgroundColor: "#5E4286",
                }}
              >
                <h1 className="text-center text-5xl font-Consolas text-yellow_foodbot">
                  {mintAmount}
                </h1>
              </div>

              <button
                className="text-4xl  py-2 px-4 rounded-lg font-Consolas bg-light_yellow_foodbot text-strong_purple_foodbot"
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  incrementMintAmount();
                }}
              >
                +
              </button>
            </div>
            <div className="mt-9 opacity-30">
              <hr
                className="text-yellow_foodbot bg-yellow_foodbot border-none"
                style={{ height: "1px" }}
              />
              <h1 className="font-Consolas text-xl text-yellow_foodbot">
                {CONFIG.DISPLAY_COST} {CONFIG.NETWORK.SYMBOL} + GAS
              </h1>
            </div>
          </div>
        )}
        {stateMint === STATE_MINTED && (
          <img
            src="assets/images/mint/congrats2.png"
            className="mx-auto w-full md:w-1/2 mt-8"
          />
        )}
      </>
    );
  };

  const FeedBack = () => {
    return (
      <>
        {blockchain.account === "" ||
          (blockchain.smartContract === null && stateMint === STATE_CONNECT && (
            <h1 className="text-sm font-Consolas mt-20 text-white">
              Please, connect to the{" "}
              <span className="text-light_yellow_foodbot">
                {CONFIG.NETWORK.NAME} network
              </span>
            </h1>
          ))}
        {stateMint === STATE_MINT && (
          <h1 className="text-sm font-Consolas mt-20 text-white">
            Please note: Once you make the <br /> purchase, you cannot undo this
            action.
          </h1>
        )}
        {stateMint === STATE_MINTING && (
          <h1 className="text-sm font-Consolas mt-20 text-white">
            Minting your {CONFIG.NFT_NAME}...
          </h1>
        )}
        {stateMint === STATE_MINTED && (
          <h1 className="text-xl font-Consolas mt-12 text-yellow_foodbot">
            {"" /*LOREP IPSUM DOLOR SIT AMET*/}
          </h1>
        )}
      </>
    );
  };

  return (
    <>
      {stateMint === STATE_CONNECT && <Disclaimer />}
      <div
        className="w-full min-h-screen bg-ligh_purple_foodbot"
        style={
          {
            /*, height: isMobile ? "100vh" : "" */
          }
        }
      >
        {/* HEADER */}
        <nav className="flex items-center justify-between flex-wrap bg-teal px-6">
          <div className="flex items-center flex-no-shrink text-white mr-6">
            <img
              src="assets/images/mint/foodbots.png"
              className="w-16 md:w-32"
            />
          </div>

          <div className="flex-grow flex items-center w-auto">
            <div className="text-sm flex-grow invisible">
              <a
                href="#responsive-header"
                className="inline-block mt-0 text-teal-lighter hover:text-white mr-4"
              >
                EMPTY
              </a>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a className="" href={DISCORD_LINK}>
                <svg
                  className="w-6 md:w-11"
                  width="71"
                  height="55"
                  viewBox="0 0 71 55"
                  fill="#FFFFFF"
                  opacity={1}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="71" height="55" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a className="" href={TWITTER_LINK}>
                <svg
                  className="w-6 md:w-11"
                  fill="#FFFFFF"
                  opacity={1}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="90px"
                  height="90px"
                >
                  <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                </svg>
              </a>
            </div>
          </div>
        </nav>
        {/* MAIN */}
        <main
          id="main"
          className={`${isMobile ? "" : "grid"} w-full`}
          style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
        >
          {/* LEFT */}
          <div id="left" className="hidden md:flex justify-center mt-10">
            <img
              src="assets/images/mint/back_left.png"
              className="w-2/3 object-contain"
            />
          </div>

          {/* CENTER */}
          <div id="center">
            <div className="flex justify-center flex-row mb-9">
              <img
                src={pathImgHeader}
                className="object-contain w-1/3 md:w-1/4"
              />

              <img
                src={`assets/images/mint/${
                  data.totalSupply === CONFIG.MAX_SUPPLY
                    ? "soldOut.png"
                    : "weAreMinting.png"
                }`}
                className="object-contain w-1/2 md:w-1/2"
              />
            </div>
            <div
              className="flex flex-col justify-center items-center text-center rounded-lg px-10 mx-4 md:mx-0 md:p-0 bg-hard_purple_foodbot"
              style={{ height: "60vh" }}
            >
              <div className="flex flex-row justify-center items-center gap-3 mt-9">
                {stateMint !== STATE_MINT && (
                  <img
                    className={`${
                      stateMint === STATE_MINTING ? "opacity-30" : ""
                    }`}
                    src="assets/images/mint/grafitti.png"
                    style={{ width: isMobile ? "15%" : "15%" }}
                  />
                )}
                <h1
                  className={`text-5xl text-white ${
                    stateMint === STATE_MINTING ? "opacity-30" : ""
                  }`}
                >
                  <span className="text-yellow_foodbot">
                    {data.totalSupply}
                  </span>
                  /{CONFIG.MAX_SUPPLY}
                </h1>
              </div>
              <Center />
              <FeedBack />
              <Button />
            </div>
          </div>

          {/* RIGHT */}
          <div
            id="right"
            className="hidden md:flex justify-center items-center mt-10"
          >
            <img
              src="assets/images/mint/back_right.png"
              className="w-2/3 object-contain"
            />
          </div>
        </main>
      </div>
    </>
    // <s.Screen>
    //   <s.Container
    //     flex={1}
    //     ai={"center"}
    //     style={{ padding: 24, backgroundColor: "var(--primary)" }}
    //     image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
    //   >
    //     <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
    //     <s.SpacerSmall />
    //     <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
    //       <s.Container flex={1} jc={"center"} ai={"center"}>
    //         <StyledImg alt={"example"} src={"/config/images/example.gif"} />
    //       </s.Container>
    //       <s.SpacerLarge />
    //       <s.Container
    //         flex={2}
    //         jc={"center"}
    //         ai={"center"}
    //         style={{
    //           backgroundColor: "var(--accent)",
    //           padding: 24,
    //           borderRadius: 24,
    //           border: "4px dashed var(--secondary)",
    //           boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
    //         }}
    //       >
    //         <s.TextTitle
    //           style={{
    //             textAlign: "center",
    //             fontSize: 50,
    //             fontWeight: "bold",
    //             color: "var(--accent-text)",
    //           }}
    //         >
    //           {data.totalSupply} / {CONFIG.MAX_SUPPLY}
    //         </s.TextTitle>
    //         <s.TextDescription
    //           style={{
    //             textAlign: "center",
    //             color: "var(--primary-text)",
    //           }}
    //         >
    //           <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
    //             {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
    //           </StyledLink>
    //         </s.TextDescription>
    //         <s.SpacerSmall />
    //         {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
    //           <>
    //             <s.TextTitle
    //               style={{ textAlign: "center", color: "var(--accent-text)" }}
    //             >
    //               The sale has ended.
    //             </s.TextTitle>
    //             <s.TextDescription
    //               style={{ textAlign: "center", color: "var(--accent-text)" }}
    //             >
    //               You can still find {CONFIG.NFT_NAME} on
    //             </s.TextDescription>
    //             <s.SpacerSmall />
    //             <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
    //               {CONFIG.MARKETPLACE}
    //             </StyledLink>
    //           </>
    //         ) : (
    //           <>
    //             <s.TextTitle
    //               style={{ textAlign: "center", color: "var(--accent-text)" }}
    //             >
    //               1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
    //               {CONFIG.NETWORK.SYMBOL}.
    //             </s.TextTitle>
    //             <s.SpacerXSmall />
    //             <s.TextDescription
    //               style={{ textAlign: "center", color: "var(--accent-text)" }}
    //             >
    //               Excluding gas fees.
    //             </s.TextDescription>
    //             <s.SpacerSmall />
    //             {blockchain.account === "" ||
    //             blockchain.smartContract === null ? (
    //               <s.Container ai={"center"} jc={"center"}>
    //                 <s.TextDescription
    //                   style={{
    //                     textAlign: "center",
    //                     color: "var(--accent-text)",
    //                   }}
    //                 >
    //                   Connect to the {CONFIG.NETWORK.NAME} network
    //                 </s.TextDescription>
    //                 <s.SpacerSmall />
    //                 <StyledButton
    //                   onClick={(e) => {
    //                     e.preventDefault();
    //                     dispatch(connect());
    //                     getData();
    //                   }}
    //                 >
    //                   CONNECT
    //                 </StyledButton>
    //                 {blockchain.errorMsg !== "" ? (
    //                   <>
    //                     <s.SpacerSmall />
    //                     <s.TextDescription
    //                       style={{
    //                         textAlign: "center",
    //                         color: "var(--accent-text)",
    //                       }}
    //                     >
    //                       {blockchain.errorMsg}
    //                     </s.TextDescription>
    //                   </>
    //                 ) : null}
    //               </s.Container>
    //             ) : (
    //               <>
    //                 <s.TextDescription
    //                   style={{
    //                     textAlign: "center",
    //                     color: "var(--accent-text)",
    //                   }}
    //                 >
    //                   {feedback}
    //                 </s.TextDescription>
    //                 <s.SpacerMedium />
    //                 <s.Container ai={"center"} jc={"center"} fd={"row"}>
    //                   <StyledRoundButton
    //                     style={{ lineHeight: 0.4 }}
    //                     disabled={claimingNft ? 1 : 0}
    //                     onClick={(e) => {
    //                       e.preventDefault();
    //                       decrementMintAmount();
    //                     }}
    //                   >
    //                     -
    //                   </StyledRoundButton>
    //                   <s.SpacerMedium />
    //                   <s.TextDescription
    //                     style={{
    //                       textAlign: "center",
    //                       color: "var(--accent-text)",
    //                     }}
    //                   >
    //                     {mintAmount}
    //                   </s.TextDescription>
    //                   <s.SpacerMedium />
    //                   <StyledRoundButton
    //                     disabled={claimingNft ? 1 : 0}
    //                     onClick={(e) => {
    //                       e.preventDefault();
    //                       incrementMintAmount();
    //                     }}
    //                   >
    //                     +
    //                   </StyledRoundButton>
    //                 </s.Container>
    //                 <s.SpacerSmall />
    //                 <s.Container ai={"center"} jc={"center"} fd={"row"}>
    //                   <StyledButton
    //                     disabled={claimingNft ? 1 : 0}
    //                     onClick={(e) => {
    //                       e.preventDefault();
    //                       claimNFTs();
    //                       getData();

    //                       setMintAmountStorage(mintAmount);
    //                       localStorage.setItem(
    //                         "mintAmount",
    //                         mintAmountStorage + 1
    //                       );
    //                     }}
    //                   >
    //                     {claimingNft ? "BUSY" : "BUY"}
    //                   </StyledButton>
    //                 </s.Container>
    //               </>
    //             )}
    //           </>
    //         )}
    //         <s.SpacerMedium />
    //       </s.Container>
    //       <s.SpacerLarge />
    //       <s.Container flex={1} jc={"center"} ai={"center"}>
    //         <StyledImg
    //           alt={"example"}
    //           src={"/config/images/example.gif"}
    //           style={{ transform: "scaleX(-1)" }}
    //         />
    //       </s.Container>
    //     </ResponsiveWrapper>
    //     <s.SpacerMedium />
    //     <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
    //       <s.TextDescription
    //         style={{
    //           textAlign: "center",
    //           color: "var(--primary-text)",
    //         }}
    //       >
    //         Please make sure you are connected to the right network (
    //         {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
    //         Once you make the purchase, you cannot undo this action.
    //       </s.TextDescription>
    //       <s.SpacerSmall />
    //       <s.TextDescription
    //         style={{
    //           textAlign: "center",
    //           color: "var(--primary-text)",
    //         }}
    //       >
    //         We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
    //         successfully mint your NFT. We recommend that you don't lower the
    //         gas limit.
    //       </s.TextDescription>
    //     </s.Container>
    //   </s.Container>
    // </s.Screen>
  );
};

export default MintApp;
