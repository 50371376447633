import { isMobile } from "react-device-detect";
import { ParallaxBanner } from "react-scroll-parallax";
import { BASE_PATH } from "./App";

const Carousel = () => {
  const images = [
    `${BASE_PATH}/assets/images/foodtown/carousel/1.jpg`,
    `${BASE_PATH}/assets/images/foodtown/carousel/2.jpg`,
    `${BASE_PATH}/assets/images/foodtown/carousel/3.jpg`,
    `${BASE_PATH}/assets/images/foodtown/carousel/4.jpg`,
    `${BASE_PATH}/assets/images/foodtown/carousel/5.jpg`,
    `${BASE_PATH}/assets/images/foodtown/carousel/1.jpg`,
    `${BASE_PATH}/assets/images/foodtown/carousel/2.jpg`,
  ];
  return (
    <div className="w-full">
      <div
        className="absolute w-full z-50"
        style={{ transform: isMobile ? "translateY(60%)" : "translateY(30%)" }}
      >
        <h1
          className="text-center text-3xl md:text-5xl text-white drop-shadow-xl "
          style={{
            textShadow: "2.5px 2.5px #00000060, 0px 0px 50px #00000060",
          }}
        >
          WELCOME TO
          <br />
          FOODIETOWN!
        </h1>
      </div>

      <div className="slider">
        <div className="slide-track">
          {[...Array(7)].map((x, i) =>
            images.map((img: string) => {
              return (
                <div className="slide" key={Math.random()}>
                  <img className="w-full" src={img} alt="" />
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="sliderReverse">
        <div className="slideReverse-track">
          {[...Array(7)].map((x, i) =>
            images.map((img: string) => {
              return (
                <div className="slideReverse" key={Math.random()}>
                  <img className="w-full" src={img} alt="" />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

const Foodietown = () => {
  return (
    <section id="welcome">
      {/* <img
        src={`${BASE_PATH}/assets/images/foodtown/SliderComida.png`}
        className="w-full mb-10"
      /> */}

      <Carousel />
      <div
        className="text-justify mt-4  px-6 md:px-80 2xl:px-80 3xl:px-80 4xl:px-rem45 text-red_foodbot"
        style={{
          textAlignLast: "center",
        }}
      >
        <p className="text-xl md:text-xl ">
          On <span className="text-purple_foodbot">FOODIETOWN</span> university
          campus, researchers were eager to develop the first kitchen robot
          capable of making food from crypto-matter,
        </p>
        <p className="mt-4 text-xl md:text-xl">
          But something went wrong when{" "}
          <span className="text-purple_foodbot">robotics</span>,
          <span className="text-purple_foodbot"> Crypto-matter</span> and
          <span className="text-purple_foodbot"> doughnuts</span> came into
          contact. Out of a quantum explosion, a small creature with a scuba
          diving suit for a head appeared to have doughnuts for a brain.
          <span className="text-purple_foodbot">
            &nbsp;The first foodbot had been spawned
          </span>
        </p>
        <p className="mt-4 text-xl md:text-xl">
          Foodbots can only eat the food that spawned them, if they eat other
          food they vomit it up and
          <span className="text-purple_foodbot">
            &nbsp;from that vomit appears a new foodbot with a brain from the
            vomited food
          </span>
          .
        </p>
        <p className="mt-4 text-xl md:text-xl">
          They have no legs, no arms, no tail, they acquire the elements in
          their environment because of their desire to look like humans.
        </p>
      </div>
      <ParallaxFoodie />
    </section>
  );
};

const ParallaxFoodie = () => {
  return (
    <>
      {/* <Parallax
        bgImage={`${BASE_PATH}/assets/images/foodtown/Foodtown.jpg`}
        bgImageAlt="the cat"
        strength={isMobile ? 25 : 100}
        className="mt-4 w-full mx-auto"
      >
        <img
          className="w-full opacity-0"
          src={`${BASE_PATH}/assets/images/foodtown/Foodtown.jpg`}
        />
      </Parallax> */}

      <ParallaxBanner
        layers={[
          {
            image: `${BASE_PATH}/assets/images/foodtown/FoodtownMobile.jpg`,
            speed: isMobile ? -5 : -10,
            expanded: true,
          },
        ]}
        className="mt-4 w-full mx-auto object-cover"
        style={{ height: isMobile ? "7rem" : "20rem" }}
      />

      {/* <Parallax strength={300} className="mt-4 w-full">
        <Background className="">
          <div
            className="h-52 bg-fixed bg-no-repeat"
            style={{
              backgroundImage: `url(${BASE_PATH}/assets/images/foodtown/Foodtown.jpg)`,
              backgroundPosition: "center",
              backgroundSize: "100vw",
            }}
          />
        </Background>
        <img
          className="w-full opacity-0"
          src={`${BASE_PATH}/assets/images/foodtown/Foodtown.jpg`}
        />
      </Parallax> */}
    </>
  );
};
export default Foodietown;
