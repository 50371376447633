import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { BASE_PATH } from "./App";

const Hero = () => {
  const [buttonHover, setButtonHover] = useState<boolean>(false);
  const navigate = useNavigate();

  const durationTransitionButton = 0.3;
  const enableInitial = 0.25;
  const disableInitial = 0.0;
  const offset = 0.1;

  const variants = {
    enableFirst: {
      opacity: 1,
      transition: {
        duration: durationTransitionButton,
        type: "easyInOut",
        delay: enableInitial - offset,
      },
    },
    disableFirst: {
      opacity: 0,
      transition: {
        duration: durationTransitionButton,
        type: "easyInOut",
        delay: disableInitial,
      },
    },
    enableSecond: {
      opacity: 1,
      transition: {
        duration: durationTransitionButton,
        type: "easyInOut",
        delay: enableInitial,
      },
    },
    disableSecond: {
      opacity: 0,
      transition: {
        duration: durationTransitionButton,
        type: "easyInOut",
        delay: disableInitial + offset,
      },
    },
  };

  return (
    <AnimatePresence>
      <div
        id="hero"
        className="items-center flex flex-col relative w-full z-10"
        //   style={{ transform: "translateY(-10%)" }}
      >
        <div id="hero_cotnainer" className="">
          <img
            className=""
            src={
              isMobile
                ? `${BASE_PATH}/assets/images/HeroAnimatedMobile2.gif`
                : `${BASE_PATH}/assets/images/HeroAnimated.gif`
            }
            alt="Hero FoodBots"
          />
          <hr
            className="w-full absolute transform -translate-y-1/2 "
            style={{
              height: "0.45rem",
              color: "#382261",
              backgroundColor: "#382261",
            }}
          />
        </div>

        <button
          className="rounded-xl bg-red_foodbot transform -translate-y-1/2 buttonHero"
          onClick={() => window.open("https://mint.foodbots.org/", "_self")}
          onMouseEnter={() => setButtonHover(true)}
          onMouseLeave={() => setButtonHover(false)}
        >
          <div className="text-white text-5xl md:text-5xl py-2 underline text-center align-middle">
            <motion.span
              variants={variants}
              animate={buttonHover ? "enableSecond" : "disableFirst"}
              style={{ opacity: 0 }}
            >
              &gt;
            </motion.span>
            <motion.span
              variants={variants}
              animate={buttonHover ? "enableFirst" : "disableSecond"}
              style={{ opacity: 0 }}
            >
              &gt;
            </motion.span>
            <span>&gt;</span>
            <span> MINT </span>
            <span>&lt;</span>
            <motion.span
              variants={variants}
              animate={buttonHover ? "enableFirst" : "disableSecond"}
              style={{ opacity: 0 }}
            >
              &lt;
            </motion.span>
            <motion.span
              variants={variants}
              animate={buttonHover ? "enableSecond" : "disableFirst"}
              style={{ opacity: 0 }}
            >
              &lt;
            </motion.span>
          </div>
        </button>
      </div>
    </AnimatePresence>
  );
};

export default Hero;
