import { useState } from "react";
import { isMobile } from "react-device-detect";
import Modal from "react-responsive-modal";

const Disclaimer = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      {open && (
        <div
          id="defaultModal"
          tabIndex={-1}
          aria-hidden="true"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full"
          style={{ backgroundColor: "rgba(0,0,0,0.65)" }}
        >
          <div
            className="relative p-4 w-full h-full mx-auto my-auto flex items-center"
            style={{ maxWidth: !isMobile ? "25%" : "" }}
          >
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg  shadow dark:bg-gray-700 ">
              <button
                onClick={(e) => {
                  setOpen(false);
                }}
                className="absolute right-0 mr-4 text-4xl text-yellow_foodbot"
              >
                X
              </button>
              {/* <!-- Modal body --> */}
              <div className="flex flex-col items-center justify-center bg-purple_foodbot rounded-lg">
                <img
                  src="assets/images/mint/walkie.png"
                  className="w-24 mt-14"
                />
                <h1 className="text-center text-2xl text-yellow_foodbot mt-4">
                  WARNING
                </h1>
                <div
                  style={{ height: "30vh", width: "90%" }}
                  className="overflow-y-scroll"
                >
                  <p className="font-Consolas text-left text-white text-sm mt-4 px-10">
                    The website provides its connected services “as available”,
                    without guarantee of any kind. Without limiting this, we
                    expressly disclaim all warranties, whether express, implied
                    or statutory, with respect to the website. The use of
                    blockchain applications is associated with a high degree of
                    technological and/or other risks. Holders and/or buyers of
                    NFT-Tokens assume these risks when accessing this type of
                    platform.
                    <br />
                    <br />
                    NFT-Tokens are non-fungible tokens that represent ownership
                    of a digital work of art only. Consequently, no information
                    on this website (or any other document mentioned on it) is
                    or can be considered as advice or an invitation to enter
                    into an agreement for any investment purpose. Furthermore,
                    since NFT-Token represents a work of art, nothing on this
                    website qualifies or is intended to be an offer of
                    securities in any jurisdiction or constitute an offer or
                    invitation to buy stocks, securities or other financial
                    product.
                    <br />
                    <br />
                    We undertake no obligation to publicly update or revise any
                    information or opinion posted on the Website. We reserve the
                    right to modify the information at any time without prior
                    notice.
                    <br />
                    <br />
                    There are risks associated with the use of cryptocurrencies,
                    including, but not limited to, the risk of hardware,
                    software, and Internet connections, the risk of introduction
                    of malicious software, and the risk of third parties gaining
                    unauthorized access to information stored in your wallet.
                    You agree and acknowledge that FoodBots shall not be liable
                    for any communication failures, interruptions, errors,
                    distortions, or delays you may experience while using the
                    ETH network, regardless of cause.
                    <br />
                    <br />
                    Blockchain asset prices are highly volatile. Fluctuations in
                    the price of other digital assets could materially and
                    adversely affect the value of your FoodBot, which may also
                    be subject to significant price volatility. We cannot
                    guarantee that Foodbot buyers will not lose money.
                    <br />
                    <br />
                    Risk of loss of private key or passphrase(s): Foodbots is
                    not responsible for your private keys, which you need to
                    access NFTs. It is your sole responsibility to protect your
                    private keys. In case of loss, Foodbots is not responsible.
                  </p>
                </div>

                <button
                  className="text-4xl py-2 px-9 rounded-xl mt-12 mb-12 bg-yellow_foodbot text-strong_purple_foodbot"
                  onClick={(e) => {
                    setOpen(false);
                  }}
                >
                  OK {" >>"}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Disclaimer;
