import { isIOS, isSafari } from "react-device-detect";
import {
  BASE_PATH,
  DISCORD_LINK,
  OPENSEA_LINK,
  SMART_CONTRACT_LINK,
  TWITTER_LINK,
} from "./App";

const Footer = () => {
  return (
    <footer
      className="mt-4 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(./assets/images/footer/FooterBackground.jpg)`,
      }}
    >
      <div
        className={`${
          isSafari || isIOS ? "text-center" : "text-justify"
        } px-6 pt-1 pb-5`}
        style={{ color: "#FFFFFF", textAlignLast: "center" }}
      >
        <img
          className="mt-5 cursor-pointer mx-auto w-12% md:w-4%"
          src={`${BASE_PATH}/assets/images/footer/FooterTwitter.png`}
          alt="twitter"
          onClick={() => window.open(TWITTER_LINK)}
        />
        <a href={TWITTER_LINK} className="text-xl">
          twitter
        </a>
        {/* <a href={TWITTER_LINK}>{TWITTER_LINK}</a> */}

        <img
          className="mt-5 cursor-pointer mx-auto w-12% md:w-4%"
          src={`${BASE_PATH}/assets/images/footer/FooterDiscord.png`}
          alt="discord"
          onClick={() => window.open(DISCORD_LINK)}
        />
        <a href={DISCORD_LINK} className="text-xl">
          discord
        </a>
        {/* <a href={DISCORD_LINK}>{DISCORD_LINK}</a> */}

        <img
          className="mt-5 cursor-pointer mx-auto w-12% md:w-5%"
          src={`${BASE_PATH}/assets/images/footer/FooterOpensea.png`}
          alt="opensea"
          onClick={() => window.open(OPENSEA_LINK)}
        />
        <a href={OPENSEA_LINK} className="text-xl cursor-pointer">
          opensea
        </a>
        {/* <a href={} className="pointer-events-none" href={OPENSEA_LINK}>
          {OPENSEA_LINK}
        </a> */}

        <img
          className="mt-5 cursor-pointer mx-auto w-12% md:w-4%"
          src={`${BASE_PATH}/assets/images/footer/FooterSmartContract.png`}
          alt="smart contract"
          onClick={() => window.open(SMART_CONTRACT_LINK)}
        />
        <a href={SMART_CONTRACT_LINK} className="text-xl">
          Smart contract
        </a>
        {/* <a className="pointer-events-none" href={SMART_CONTRACT_LINK}>
          {SMART_CONTRACT_LINK}
        </a> */}
      </div>
    </footer>
  );
};

export default Footer;
