import { motion, useTransform, useViewportScroll } from "framer-motion";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import { Parallax } from "react-scroll-parallax";
import { BASE_PATH } from "./App";

const Faq = () => {
  const { scrollYProgress } = useViewportScroll();
  const y = useTransform(scrollYProgress, [0, 1], [900, 100]);

  return (
    <section id="">
      <img
        alt="Faq Title"
        className={`w-full mt-8 ${isMobile ? "mb-8" : ""}`}
        src={`${BASE_PATH}/assets/images/faq/${
          isMobile ? "FaqTitleMobile.png" : "FaqTitle.png"
        }`}
      />

      <Parallax className="w-full" translateX={[20, -20]}>
        <img
          alt="cloud title"
          className="w-1/2 md:w-1/4 -mt-10 mx-auto"
          src={`${BASE_PATH}/assets/images/faq/nubeTitle1.png`}
        />
      </Parallax>
      <Parallax className="w-full" translateX={[-25, 15]}>
        <img
          alt="cloud title"
          className="w-1/4 md:w-1/6 -mt-2 md:-mt-10 mx-auto"
          src={`${BASE_PATH}/assets/images/faq/nubeTitle1.png`}
        />
      </Parallax>
      <div className={`${!isMobile ? "grid grid-cols-3" : ""}`}>
        {!isMobile && (
          <div className="relative">
            <img
              alt="floating cloud"
              className="absolute right-0 mr-5 w-1/4 mt-44 floatingCloud"
              src={`${BASE_PATH}/assets/images/faq/nube2.png`}
            />
          </div>
        )}
        <div
          className={`${
            isIOS || isSafari ? "text-center" : "text-justify"
          } text-lg ${!isMobile ? "w-full px-14" : "px-6"} text-red_foodbot`}
          style={{ textAlignLast: "center" }}
        >
          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot">
            WHAT IS FOODBOTS?
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            Foodbots are hooligan bots living on ethereum mainnet that are
            powered by food. the collection consists of 9999 randomly generated
            foodbots. our main idea is to create a community, which consists of
            enthusiasts, investors and people who love nfts.
          </p>

          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot mt-4">
            What is the total supply?
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            9999 foodbots will be generateD.
          </p>

          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot mt-4">
            Utility?
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            We have many things coming up on our roadmap, breeding mechanic,
            creating your own FoodBot using babies, VX FoodBot (Sandbox), launch
            of Foodiestown's map.....
          </p>

          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot mt-4">
            Where can i mint?
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            Mint process will be handle though our website
          </p>

          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot mt-4">
            how can i mint
          </h1>
          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot">
            my FoodBots?
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            Connect your Metamask account to the website on the minting day,
            select the quantity and pay the cost. You will need a Metamask
            wallet and some ETH.
          </p>

          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot mt-4">
            How much is the minting price
          </h1>
          {/* <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot ">
            and process?
          </h1> */}
          {/* CAMBIAR EL TEXT CENTER DE ABAJO, SOLO LO HE USADO POR SAFARI  */}
          <p className="text-xl text-center md:text-base xl:text-xl">
            TBA, check our discord to know more
          </p>

          <img
            className="mt-5 w-1/2 xl:w-1/3 mx-auto"
            src={`${BASE_PATH}/assets/images/faq/FaqFooter.jpg`}
            alt="footer"
          />
        </div>
        {!isMobile && (
          <div className="relative">
            <motion.img
              className="absolute left-0 ml-5 w-1/6"
              src={`${BASE_PATH}/assets/images/faq/salchichon.png`}
              style={{ y }}
            />
            <img
              alt="floating cloud"
              className="absolute left-0 ml-5 w-1/6 floatingCloud"
              src={`${BASE_PATH}/assets/images/faq/nube3.png`}
              style={{ marginTop: "30rem" }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Faq;
