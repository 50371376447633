import { useTransform, useViewportScroll, motion } from "framer-motion";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import { BASE_PATH } from "./App";

const Roadmap = () => {
  const { scrollYProgress } = useViewportScroll();
  const velocity1 = useTransform(scrollYProgress, (value) => value * -1000);
  const velocity2 = useTransform(scrollYProgress, (value) => value * -2000);

  const velocity3 = useTransform(scrollYProgress, (value) => value * -2500);
  const velocity4 = useTransform(scrollYProgress, (value) => value * -1000);

  return (
    <section className="mt-10" id="roadmap">
      <img
        alt="title"
        className="w-full"
        src={`${BASE_PATH}/assets/images/${
          isMobile ? "RoadmapTitleMobile.png" : "RoadmapTitle.png"
        }`}
      />
      <div
        className={`${!isMobile ? "grid " : ""}`}
        style={{ gridTemplateColumns: "27.5fr 45fr 27.5fr" }}
      >
        {!isMobile && (
          <div className="relative">
            <img
              alt="line"
              className="absolute ml-auto mr-auto left-0 right-0 h-full pt-10  pb-32 "
              src={`${BASE_PATH}/assets/images/roadmap/line.jpg`}
            />
            <motion.img
              alt="animation"
              className="absolute ml-auto mr-auto left-0 right-0 w-1/6 mt-12"
              src={`${BASE_PATH}/assets/images/roadmap/foodbot1.png`}
              style={{ y: velocity2, marginTop: "95rem" }}
            />
            <motion.img
              alt="animation"
              className="absolute ml-auto mr-auto left-0 right-0 w-1/6 "
              src={`${BASE_PATH}/assets/images/roadmap/donut2.png`}
              style={{ marginTop: "80rem", y: velocity1 }}
            />
            <img
              alt="animation"
              className="absolute w-1/6 mt-80 right-0 mr-12 floating"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim1.png`}
              // style={{ marginTop: "35rem" }}
            />
            <img
              alt="animation"
              className="absolute  ml-24  left-0 mr-12 floating2"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim2.png`}
              style={{ marginTop: "35rem", width: "10%" }}
            />
            <img
              alt="animation"
              className="absolute  ml-36  left-0 mr-12 floating2"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim3.png`}
              style={{ marginTop: "45rem", width: "10%" }}
            />
            <img
              alt="animation"
              className="absolute w-1/6 right-0 mr-12 floating"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim4.png`}
              style={{ marginTop: "50rem" }}
            />
          </div>
        )}
        <div
          className={`${
            isSafari || isIOS ? "text-center" : "text-justify"
          } text-lg mt-4 ${!isMobile ? "w-full " : ""} text-red_foodbot`}
          style={{ textAlignLast: "center" }}
        >
          {/* <p className="text-purple_foodbot">&lt;------ 10% ------&gt;</p>
          <p className="text-xl xl:text-2xl">pre-launch twitter</p>
          <p className="text-xl xl:text-2xl">giveaways</p>
          <p className="text-purple_foodbot">&lt;------ 20% ------&gt;</p>
          <p className="text-xl xl:text-2xl">discord meme contest</p>
          <p className="text-purple_foodbot">&lt;------ 30% ------&gt;</p>
          <p className="text-xl xl:text-2xl">discord gaming</p>
          <p className="text-xl xl:text-2xl">contest</p>

          <p className="text-purple_foodbot">&lt;------ 40% ------&gt;</p>
          <p className="text-xl xl:text-2xl">mint day (TBA)</p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            Holder airdrop for the first 2500
          </p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">nft give away</p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            Holder airdrop for the first 5000
          </p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">nft give away</p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            Holder airdrop for the first 7500
          </p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">nft give away</p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            Holder airdrop for all holders 100% minted
          </p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">nft give away</p>

          <p className="text-purple_foodbot">&lt;------ 80% ------&gt;</p>
          <p className="text-xl xl:text-2xl">reveal</p>
          <p className="text-purple_foodbot">&lt;------ 90% ------&gt;</p>
          <p className="text-xl xl:text-2xl">merchandising</p>
          <p className="text-purple_foodbot">&lt;------ 100% ------&gt;</p>
          <p className="text-xl xl:text-2xl">Utility</p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            Food NFT Airdrop (for Breeding)
          </p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            Eat & Puke (Breeding Mechanic)
          </p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            FoodBots Maker (Make your own FoodBot)
          </p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            VX Avatar Release (Sandbox)
          </p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">
            Foodiestown's Interactive Map Launch (P2E)
          </p>
          <p className="-mt-2 text-2xl text-purple_foodbot">-</p>
          <p className="-mt-2 md:text-sm text-lg xl:text-lg">Redacted</p> */}
          {/* <p className="text-xl xl:text-2xl">
            metaverse and{" "}
            <span
              className="text-white bg-red_foodbot px-2 rounded-xl"
              style={{
                paddingTop: "0.025rem",
                paddingBottom: "0.025rem",
                WebkitTransform: "rotate(-10deg)",
                display: "inline-block",
              }}
            >
              REDACTED
            </span>
          </p> */}
          <img
            src={`${BASE_PATH}/assets/images/roadmap/roadmap.jpg`}
            className=""
            // style={{ width: "150%" }}
            alt="Roadmap"
          />
          <p className="text-md mt-4 px-2 md:px-0">
            .- / ... .--. .- -.-. . ... .... .. .--. / .. ... / -.-. --- --
          </p>
          <p className="text-md px-2 md:px-0">
            .. -. --. / ..-. --- .-. / ..- ... / ---... ---
          </p>
          <img
            className="mt-4 w-1/2 md:w-1/5 lg:w-1/3 mx-auto"
            src={`${BASE_PATH}/assets/images/Roadmap.gif`}
            alt="roadmap gif"
          />
        </div>
        {!isMobile && (
          <div className="relative w-full">
            <img
              alt="line"
              className="absolute ml-auto mr-auto left-0 right-0 h-full pt-10  pb-32 "
              src={`${BASE_PATH}/assets/images/roadmap/line.jpg`}
            />
            <motion.img
              alt="animation"
              className="absolute ml-auto mr-auto left-0 right-0 w-1/6"
              src={`${BASE_PATH}/assets/images/roadmap/donut1.png`}
              style={{ marginTop: "55rem", y: velocity4 }}
            />
            <motion.img
              alt="animation"
              className="absolute ml-auto mr-auto left-0 right-0 w-1/6"
              src={`${BASE_PATH}/assets/images/roadmap/foodbot3.png`}
              style={{ marginTop: "120rem", y: velocity3 }}
            />
            <img
              alt="animation"
              className="absolute w-1/6 left-0 ml-36 floating"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim6.png`}
              style={{ marginTop: "37rem" }}
            />
            <img
              alt="animation"
              className="absolute w-1/6 right-0 mr-40 floating2"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim5.png`}
              style={{ marginTop: "34rem" }}
            />
            <img
              alt="animation"
              className="absolute w-1/6 right-0 mr-40 floating"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim7.png`}
              style={{ marginTop: "46rem" }}
            />
            <img
              alt="animation"
              className="absolute w-1/6 left-0 ml-24 floating2"
              src={`${BASE_PATH}/assets/images/roadmap/donutAnim8.png`}
              style={{ marginTop: "50rem" }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Roadmap;
