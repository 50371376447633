import { motion, useTransform, useViewportScroll } from "framer-motion";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import { Parallax } from "react-scroll-parallax";
import { BASE_PATH } from "./App";

const Utility = () => {
  const { scrollYProgress } = useViewportScroll();
  const y = useTransform(scrollYProgress, [0, 1], [900, 100]);

  return (
    <section id="">
      <img
        alt="Faq Title"
        className={`w-full mt-8 ${isMobile ? "mb-8" : ""}`}
        src={`${BASE_PATH}/assets/images/utility/${
          !isMobile ? "title.png" : "titleMobile.png"
        }`}
      />

      {/* <Parallax className="w-full" translateX={[20, -20]}>
        <img
          alt="cloud title"
          className="w-1/2 md:w-1/4 -mt-10 mx-auto"
          src={`${BASE_PATH}/assets/images/faq/nubeTitle1.png`}
        />
      </Parallax>
      <Parallax className="w-full" translateX={[-25, 15]}>
        <img
          alt="cloud title"
          className="w-1/4 md:w-1/6 -mt-2 md:-mt-10 mx-auto"
          src={`${BASE_PATH}/assets/images/faq/nubeTitle1.png`}
        />
      </Parallax> */}
      <div className={`${!isMobile ? "grid grid-cols-3 mt-5" : ""}`}>
        {!isMobile && (
          <div className="relative">
            <img
              alt="animation"
              className="absolute w-1/6 right-0 bottom-0 top-auto mr-9 floating"
              src={`${BASE_PATH}/assets/images/utility/volador3.png`}
              style={{ marginBottom: "20%" }}
              // style={{ marginTop: "35rem" }}
            />
            <img
              alt="animation"
              className="absolute w-1/6  right-0 top-auto bottom-0 left-0 ml-auto mr-auto floating"
              src={`${BASE_PATH}/assets/images/utility/volador1.png`}
              style={{ marginBottom: "-30%" }}
              // style={{ marginTop: "35rem" }}
            />
          </div>
        )}
        <div
          className={`${
            isIOS || isSafari ? "text-center" : "text-justify"
          } text-lg ${!isMobile ? "w-full px-14" : "px-6"} text-red_foodbot`}
          style={{ textAlignLast: "center" }}
        >
          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot">
            EAT AND PUKE <br />
            (BREEDING MECHANIC)
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            FoodBots will be able to consume Food (Airdroped to all hodlers) if
            the food consumed is not the one that they own, they will be able to
            spawm a baby Foobot ( by puking puaggg)
          </p>
          <img
            src={`${BASE_PATH}/assets/images/utility/1.png`}
            className="w-full mx-auto mt-2"
          />
          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot mt-14">
            FoodBots Maker <br /> (Make your own FoodBot)
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            Baby Foodbots sure look cute! But be aware they will try to grab
            anything within their reach to evolve to a grown up Foodbot.
          </p>

          <img
            src={`${BASE_PATH}/assets/images/utility/2.png`}
            className="w-full mx-auto mt-2"
          />
          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot mt-14">
            VX Avatar Release <br />
            (Sandbox)
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            FoodBots are out of control and have invaded Sandbox. A VX Avatar
            for Sandbox will be release.
          </p>

          <img
            src={`${BASE_PATH}/assets/images/utility/3.png`}
            className="w-2/3 mx-auto mt-2"
          />
          <h1 className="text-2xl text-center md:text-xl xl:text-3xl text-purple_foodbot ">
            Foodiestown's Interactive Map Launch (P2E)
          </h1>
          <p className="text-xl md:text-base xl:text-xl">
            Oh poor Foodiestown, the city is a mess, the FoodBots will need your
            help to conquer it completely.
          </p>
        </div>
        {!isMobile && (
          <div className="relative">
            <img
              alt="animation"
              className="absolute w-1/6 left-0 top-auto bottom-0 floating"
              src={`${BASE_PATH}/assets/images/utility/volador2.png`}
              style={{ marginBottom: "8%" }}
              // style={{ marginTop: "35rem" }}
            />
            <img
              alt="animation"
              className="absolute w-1/6  right-0 top-auto bottom-0 left-0 ml-auto mr-auto floating"
              src={`${BASE_PATH}/assets/images/utility/volador4.png`}
              style={{ marginBottom: "40%" }}
              // style={{ marginTop: "35rem" }}
            />
          </div>
        )}
      </div>
      <img
        src={`${BASE_PATH}/assets/images/utility/${
          isMobile ? "footerUtilityMobile.png" : "footerUtility.png"
        }`}
        className="w-full"
      />
    </section>
  );
};

export default Utility;
