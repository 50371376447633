import { useEffect, useState } from "react";

interface TimeLeftInfo {
  days?: number | string;
  hours?: number | string;
  minutes?: number | string;
  seconds?: number | string;
}

export const startDate = new Date("May 6, 2022 16:00:00 +0200");

const calculateTimeLeft = (): TimeLeftInfo => {
  const currentDate = new Date().getTime();
  let difference = startDate.getTime() - currentDate;
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours:
        Math.floor((difference / (1000 * 60 * 60)) % 24) < 10
          ? "0" + Math.floor((difference / (1000 * 60 * 60)) % 24)
          : Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes:
        Math.floor((difference / 1000 / 60) % 60) < 10
          ? "0" + Math.floor((difference / 1000 / 60) % 60)
          : Math.floor((difference / 1000 / 60) % 60),
      seconds:
        Math.floor((difference / 1000) % 60) < 10
          ? "0" + Math.floor((difference / 1000) % 60)
          : Math.floor((difference / 1000) % 60),
    };
  } else {
    timeLeft = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
    };
  }
  return timeLeft;
};

const Counter = () => {
  const [timeLeft, setTimeLeft] = useState<TimeLeftInfo>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });
  return (
    <div className="flex flex-row items-center gap-5">
      <div className="flex flex-col items-center">
        <h1
          className="text-white text-3xl md:text-5xl"
          style={{
            fontWeight: "400",
            lineHeight: "44.3px",
            letterSpacing: "5%",
          }}
        >
          {timeLeft.days}
        </h1>
        <h1
          className="text-white text-xs md:text-sm"
          style={{
            fontWeight: "400",
            // fontSize: "14px",
            lineHeight: "12.92px",
            letterSpacing: "5%",
          }}
        >
          DAYS
        </h1>
      </div>
      <div className="flex flex-col items-center">
        <h1
          className="text-white text-3xl md:text-5xl"
          style={{
            fontWeight: "400",

            lineHeight: "44.3px",
            letterSpacing: "5%",
          }}
        >
          {timeLeft.hours}
        </h1>
        <h1
          className="text-white text-xs md:text-sm"
          style={{
            fontWeight: "400",
            // fontSize: "14px",
            lineHeight: "12.92px",
            letterSpacing: "5%",
          }}
        >
          HOURS
        </h1>
      </div>
      <div className="flex flex-col items-center">
        <h1
          className="text-white text-3xl md:text-5xl"
          style={{
            fontWeight: "400",

            lineHeight: "44.3px",
            letterSpacing: "5%",
          }}
        >
          {timeLeft.minutes}
        </h1>
        <h1
          className="text-white text-xs md:text-sm"
          style={{
            fontWeight: "400",
            // fontSize: "14px",
            lineHeight: "12.92px",
            letterSpacing: "5%",
          }}
        >
          MIN
        </h1>
      </div>
      <div className="flex flex-col items-center">
        <h1
          className="text-white text-3xl md:text-5xl"
          style={{
            fontWeight: "400",
            lineHeight: "44.3px",
            letterSpacing: "5%",
          }}
        >
          {timeLeft.seconds}
        </h1>
        <h1
          className="text-white text-xs md:text-sm"
          style={{
            fontWeight: "400",
            // fontSize: "14px",
            lineHeight: "12.92px",
            letterSpacing: "5%",
          }}
        >
          SECS
        </h1>
      </div>
    </div>
  );
};

export default Counter;
